import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const PlanningGuideContainer = styled.div`
  padding: 0 6.7rem 6rem;
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  @media (max-width: 1023px) {
    padding: 0 6rem 6rem;
  }

  @media (max-width: 799px) {
    padding: 0 2rem 2rem;
  }

  @media (max-width: 480px) {
    padding: 0 1rem 1rem;
  }
  .comm__wrapper {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: ${props =>
    `linear-gradient(-90deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)), url(${
      props.image
    })`};
    /* background-image: ${props => `url(${props.image})`}; */
    background-size: cover;
    background-repeat: no-repeat;
  }

  .mktoFieldWrap:not(.mktoRequiredField) label.mktoLabel.mktoHasWidth {
    margin-left: 25px;
  }
  label.mktoLabel.mktoHasWidth {
    width: 100% !important;
  }

  input#Email {
    width: 250px !important;
}

label.mktoLabel.mktoHasWidth {
    margin-bottom: 5px;
}

  .mktoLogicalField.mktoCheckboxList {
    width: 20px !important;
    position: absolute;
  }

  .mktoGutter.mktoHasWidth {
    display: none;
  }

  .mktoOffset {
    display: none;
  }
  .comm__wrapper {
    display: flex;
  }

  button.mktoButton {
    border: none;
    background-color: #ef7d25 !important;
    background: #ef7d25 !important;
    box-shadow: none !important;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: 'Roboto',sans-serif;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    width: 100%;
    padding: .9375rem 1.875rem !important;
  }

  .mktoAsterix {
    float: left !important;
    padding-left: 0 !important;
    padding-right: 5px !important;
}

label.mktoLabel.mktoHasWidth {
    font-weight: 400 !important;
}

form#mktoForm_2025 {
    margin: 30px;
}

span.mktoButtonWrap.mktoNative {
    margin-left: 0 !important;
}
  .comm__text-wrapper {
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    p {
      max-width: 400px;
      margin: 0 0 20px 0;
    }
    h3 {
      max-width: 500px;
      font-size: 36px;
      margin: 0 0 20px 0;
    }
    button,
    .button {
      background-color: #ef7d25 !important;
      background: #ef7d25 !important;
      color: #fff;
      font-size: 1.4rem;
      font-family: "Roboto", sans-serif;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      text-transform: uppercase;
      padding: 1.5rem 3rem;
      letter-spacing: 2px;
      font-weight: 600;
      cursor: pointer;
      display: inline-block;
      position: relative;
      text-decoration: none;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.2);
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        transition: all 0.4s ease;
      }
      &:hover:before {
        width: 100%;
      }
    }
    @media (max-width: 480px) {
      margin: 15px;
    }
  }
`;

const query = graphql`
  query {
    file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
  }
`;

const PlanningGuide = () => (
  <StaticQuery
    query={query}
    render={data => (
      <PlanningGuideContainer image='https://www.procurementleaders.com/AcuCustom/Sitename/DAM/136/CPO-planning-guide.jpg'>
        <div className='comm__wrapper'>
          <div className='comm__text-wrapper'>
            <h3>CPO Planning Survey 2020</h3>
            <p>
              Take part in the CPO Planning Survey 2020 and receive the findings
              to your inbox
            </p>

            <a
              className='button'
              href='https://procurementleaders.eu.qualtrics.com/jfe/form/SV_cHgdXyjOYvJqlq5'
              rel='noopener'
              target='_blank'
            >
              Complete the survey here
            </a>
          </div>
        </div>
      </PlanningGuideContainer>
    )}
  />
);

export default PlanningGuide;
